import $ from 'jquery'

$(document).on('turbolinks:load', function () {

  $('.create-single-product').on('click', function () {
    var json = this.dataset.item
    var cd_id = this.dataset.id
    var data = JSON.parse(json)
    var sku = data[0]
    var title = data[1]
    // var description = data.product_data.description
    var imageUrl = data[2]
    var urlSplit = imageUrl?.split('/') || []
    var imageName = urlSplit[urlSplit.length - 1]
    var quantity = data[3]

    $('#single-product-create-modal .modal-body #channel_product_id').val(cd_id)
    $('#single-product-create-modal .modal-body #product_title').val(title)
    $('#single-product-create-modal .modal-body #product_sku').val(sku)
    // $('#single-product-create-modal .modal-body #product_description').val(description)
    // $('#single-product-create-modal .modal-body #product_photo').attr("src", imageUrl)
    // $('#single-product-create-modal .modal-body #product_photo').attr("filename", imageName)
    $('#single-product-create-modal .modal-body #product_total_stock').val(quantity)
    if (imageName !== '') {
      $('#single-product-create-modal .modal-body #photo-label').text(`Photo ${imageName} Attached`)
    }
    $('#single-product-create-modal').modal('show')
  })

  $('.create-single-product-mapping').on('click', function () {
    var json = this.dataset.item
    var cd_id = this.dataset.id
    var data = JSON.parse(json)
    var sku = data[0]
    var title = data[1]
    // var description = data.product_data.description
    // var imageUrl = data[0].item_data.product_data.PictureDetails.GalleryURL
    // var urlSplit = imageUrl.split('/')
    // var imageName = urlSplit[urlSplit.length - 1]
    // var quantity = data.product_data.Quantity

    $('#single-product-create-modal .modal-body #channel_product_id').val(cd_id)
    $('#single-product-create-modal .modal-body #product_title').val(title)
    $('#single-product-create-modal .modal-body #product_sku').val(sku)
    // $('#single-product-create-modal .modal-body #product_description').val(description)
    // $('#single-product-create-modal .modal-body #product_photo').attr("src", imageUrl)
    // $('#single-product-create-modal .modal-body #product_photo').attr("filename", imageName)
    // $('#single-product-create-modal .modal-body #product_total_stock').val(quantity)
    // if(imageName  !== '' )
    // {
    //   $('#single-product-create-modal .modal-body #photo-label').text(`Photo ${imageName} Attached`)
    // }
    $('#single-product-create-modal').modal('show')
  })
  $(document).on('click', '.shipment_modal', function () {
    var parent = $(this).parent().parent()
    // var quantity = $(this).parent().parent().find('.order-quantity')[0].innerHTML
    var length = $(parent).find('.length-value').val() || 0
    var width = $(parent).find('.width-value').val() || 0
    var height = $(parent).find('.height-value').val() || 0
    var weight = $(parent).find('.weight-value').val() || 0
    $('#channel_order_id').val(this.dataset.id)
    $('#mail-service-roles-modal').find('.hidden_length').val(length)
    $('#mail-service-roles-modal').find('.hidden_weight').val(weight)
    $('#mail-service-roles-modal').find('.hidden_height').val(height)
    $('#mail-service-roles-modal').find('.hidden_width').val(width)
    $('.mail_service_rule_mail_service_labels_attributes_0_weight').val(weight)
    $('.mail_service_rule_mail_service_labels_attributes_0_height').val(height)
    $('.mail_service_rule_mail_service_labels_attributes_0_length').val(length)
    $('.mail_service_rule_mail_service_labels_attributes_0_width').val(width)
    $('#mail-service-roles-modal').modal('show')
  })

  $('.create-multi-product').on('click', function () {
    var json = this.dataset.item
    var cd_id = this.dataset.id
    var data = JSON.parse(json)
    var sku = data[0]
    var title = data[1]
    // var description = data.product_data.description

    $('#multi-product-create-modal .modal-body #channel_product_id').val(cd_id)
    $('#multi-product-create-modal .modal-body #product_title').val(title)
    $('#multi-product-create-modal .modal-body #product_sku').val(sku)
    // $('#multi-product-create-modal .modal-body #product_description').val(description)
    $('#multi-product-create-modal').modal('show')
  })

  $('.create-multi-product-mapping').on('click', function () {
    var json = this.dataset.item
    var cd_id = this.dataset.id
    var data = JSON.parse(json)
    var sku = data[0]
    var title = data[1]
    // var description = data.product_data.description

    $('#multi-product-create-modal .modal-body #channel_product_id').val(cd_id)
    $('#multi-product-create-modal .modal-body #product_title').val(title)
    $('#multi-product-create-modal .modal-body #product_sku').val(sku)
    // $('#multi-product-create-modal .modal-body #product_description').val(description)
    $('#multi-product-create-modal').modal('show')
  })

  $('.productSearchBtn').on('click', function () {
    $(this).closest('td').find('.productSearchContainer').toggleClass('d-none', 3000);
    $(this).closest('td').find('.productSearch').toggleClass('d-none', 3000);
    $(this).closest('td').find('.productSearch').trigger("focus")
  })

  // $('.productSearch').on('focusout', function () {
  //   if ($('.productSearch').value === ''){
  //     $(this).closest('td').find('.productSearchContainer').toggleClass('d-none', 3000);
  //     $(this).closest('td').find('.productSearch').toggleClass('d-none', 3000);
  //   }
  // })
  $('#q_status_eq, #product_mapping, .order-mapping-select, .product-mapping-select, #q_is_active_eq').on('change', function () {
    $('.product-mapping-request').trigger('click')
  })

  $('.bulk-unmap-objects').on('click', function () {
    var checked_ids = $('input[name="object_ids[]"]:checked').map((i, el) => $(el).val()).get();
    $.ajax({
      url: '/product_mappings/bulk_unmap',
      type: 'POST',
      data: { ids: checked_ids},
      success: function (response) {
        $('#archive-modal').modal('hide')
        var message = $('.jquery-selected-alert')
        message.removeClass('d-none bg-danger bg-success')
        message.html(response.message).addClass(response.status ? 'bg-success' : 'bg-danger')
        $('input[name="select-switch"]:checked').prop('checked', false);
        message.fadeTo(2500, 500).slideUp(500, function () {
          message.slideUp(500);
        });
      }
    })
  })


})